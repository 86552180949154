<template>
  <div class="invoice-management">
    <div class="m-title">开票信息</div>
    <el-form
      :model="invoiceForm"
      label-width="120px"
      size="medium"
      :rules="invoiceFormRules"
      ref="invoiceFormRef"
    >
      <el-form-item label="发票抬头：" prop="c_name">
        <el-input
          placeholder="请输入发票抬头"
          v-model="invoiceForm.c_name"
        ></el-input>
      </el-form-item>
      <el-form-item label="发票税号：" prop="duty_num" class="item">
        <el-input
          placeholder="请输入发票税号"
          v-model="invoiceForm.duty_num"
        ></el-input>
      </el-form-item>
      <el-form-item class="item">
        <div class="more" @click="isShow">
          <el-button type="text">更多选填项（非必填）</el-button>
        </div>
      </el-form-item>
      <div v-show="isShowMore">
        <el-form-item label="注册地址：">
          <el-input
            placeholder="请输入注册地址"
            v-model="invoiceForm.address"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册电话：">
          <el-input
            placeholder="请输入注册电话"
            v-model="invoiceForm.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户银行：">
          <el-input
            placeholder="请输入开户银行"
            v-model="invoiceForm.bank"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账号：">
          <el-input
            placeholder="请输入银行账号"
            v-model="invoiceForm.account"
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item label="电子邮箱：" prop="email">
        <el-input
          placeholder="请输入电子邮箱"
          v-model="invoiceForm.email"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注：" prop="remarks">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="可填写备注信息"
          v-model="invoiceForm.remarks"
          maxlength="80"
          show-word-limit
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="info"
          size="medium"
          @click="sumbitInvoiceInfo()"
          :disabled="isDisabledSave"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import {
    sumbitInvoiceInfoApi,
    getInvoiceInfoApi
  } from '@/api/user'
  export default {
    name: 'invoiceManagement',
    data() {
      var checkEmail = (rule, value, callback) => {
        const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
        if (!value) {
          return callback(new Error('邮箱不能为空'))
        }
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱格式'))
        }
      }
      return {
        invoiceForm: {
          c_name: '',
          duty_num: '',
          address: '',
          phone: '',
          bank: '',
          account: '',
          email: '',
          remarks: '',
        },
        isShowMore: false,
        isDisabledSave: false,
        invoiceFormRules: {
          c_name: [{
            required: true,
            message: '请输入发票抬头',
            trigger: 'blur'
          }],
          duty_num: [{
            required: true,
            message: '请输入发票税号',
            trigger: 'blur'
          }],
          email: [{
            required: true,
            trigger: 'blur',
            validator: checkEmail
          }],
        }
      }
    },
    created() {
      this.getInvoiceInfo()
    },
    methods: {
      //发票信息初始化
      async getInvoiceInfo() {
        const {
          data: res
        } = await getInvoiceInfoApi()
        if (res.status !== 10000) {
          return this.$message.error(res.message)
        }
        if (res.result.constructor === Object) {
          this.invoiceForm = res.result
        } else {
          this.invoiceForm = {}
        }
      },
      isShow() {
        this.isShowMore = !this.isShowMore
      },
      sumbitInvoiceInfo() {
        this.$refs.invoiceFormRef.validate(async (valid) => {
          if (!valid) return;
          //  添加功能
          const {
            data: res
          } = await sumbitInvoiceInfoApi(this.invoiceForm)
          if (res.status != 10000) {
            return this.$message.error(res.message)
          }
          this.$message.success('保存成功')
        })
      }
    },
  }

</script>

<style lang="less" scoped>
.invoice-management {
  .m-title {
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    border-bottom: 1px solid #e4e7ed;
    text-align: center;
  }

  .el-input {
    width: 60%;
  }

  /deep/ .el-textarea__inner {
    width: 70%;
  }

  /deep/ .el-input__count {
    bottom: 8%;
    right: 32%;
  }

  .el-textarea {
    width: 86%;
  }

  .item {
    margin: 0;
  }

  .more {
    width: 60%;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
